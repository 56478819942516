import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/angular-translate",
  "date": "2016-04-11",
  "title": "ANGULAR TRANSLATE",
  "author": "admin",
  "tags": ["development", "javascript", "angular.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Angular Translate is an AngularJS module called angular-translate which makes i18n and l10n easier with your Angular application. The acronym i18n stands for internationalization, and l10n for localization."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Why Angular Translate?`}</h2>
    <p>{`This library includes `}<inlineCode parentName="p">{`pluralization`}</inlineCode>{` as well as `}<inlineCode parentName="p">{`lazy loading`}</inlineCode>{`. The library also contains `}<inlineCode parentName="p">{`filters`}</inlineCode>{` and `}<inlineCode parentName="p">{`directives`}</inlineCode>{`. it is also very flexible in terms of building your own `}<inlineCode parentName="p">{`storages`}</inlineCode>{`, `}<inlineCode parentName="p">{`loaders`}</inlineCode>{` and `}<inlineCode parentName="p">{`error handlers`}</inlineCode>{`.`}</p>
    <h2>{`Sample App`}</h2>
    <p>{`Let’s build a sample app to try out angular-translate.`}</p>
    <p>{`Create `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file and insert the code below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
<html ng-app="app">
<head>
  <meta charset="UTF-8">
  <title>Angular Translate Intro</title>
  <link rel="stylesheet" href="./css/style.css">
</head>
<body ng-controller="ctrl">
  <div class="dictionary">
    <h1>{{'title' | translate}}</h1>
    <p>{{'description' | translate}}</p>
    <input type="button" ng-click="changeLang('en')" value="English">
    <input type="button" ng-click="changeLang('zh-cn')" value="Chinese">
  </div>
 
  <script src="./js/angular.min.js"></script>
  <script src="./js/angular-translate.min.js"></script>
  <script src="./js/app.js"></script>
</body>
</html>
`}</code></pre>
    <p>{`We are referencing `}<inlineCode parentName="p">{`angular.min.js`}</inlineCode>{` as well as `}<inlineCode parentName="p">{`angular-translate.min.js`}</inlineCode>{` libraries here. You can alternatively reference these libraries from teh CDN.`}</p>
    <ul>
      <li parentName="ul">{`AngularJS – `}<a parentName="li" {...{
          "href": "https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.5.3/angular.min.js"
        }}>{`https://cdnjs.cloudflare.com/ajax/libs/angular.js/1.5.3/angular.min.js`}</a></li>
      <li parentName="ul">{`Angular Translate – `}<a parentName="li" {...{
          "href": "https://cdnjs.cloudflare.com/ajax/libs/angular-translate/2.10.0/angular-translate.min.js"
        }}>{`https://cdnjs.cloudflare.com/ajax/libs/angular-translate/2.10.0/angular-translate.min.js`}</a></li>
    </ul>
    <p>{`Now we need to create an `}<inlineCode parentName="p">{`app.js`}</inlineCode>{` file and insert the code below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var app = angular.module('app', ['pascalprecht.translate']);
 
app.config(function($translateProvider) {
 
    $translateProvider
      .translations('en', {
        "title": "Car",
        "description": "A motor vehicle with four wheels;
          usually propelled by an internal combustion engine."
      })
      .translations('zh-cn', {
        "title": "汽车",
        "description": "机动车与四个车轮;通常由内燃机驱动。"
      });
 
    $translateProvider.preferredLanguage('en');
 
});
 
app.controller('ctrl', [
  '$scope', '$translate',
  function($scope, $translate) {
    $scope.changeLang = function changeLangFn(langKey) {
      $translate.use(langKey);
    };
  }
]);
`}</code></pre>
    <p>{`In the above code we inject the angular-translate module into our code. This way angular-translate gets to control our web pages.`}</p>
    <h3>{`Language Resource Files`}</h3>
    <p>{`Instead of definining translations inside our `}<inlineCode parentName="p">{`app.js`}</inlineCode>{`, we can define the files statically outside a different location.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$translateProvider.useStaticFilesLoader({
    prefix: '/js/',
    suffix: '/en.json'
});
`}</code></pre>
    <p>{`The result will appear as the following. You can switch between languages by clicking the buttons.`}</p>
    <iframe height="265" style={{
      "width": "100%"
    }} scrolling="no" title="Angular Translate" src="https://codepen.io/szaranger/embed/QNQvYd?height=265&theme-id=default&default-tab=js,result" frameBorder="no" allowtransparency="true" allowFullScreen="true">
  See the Pen <a href='https://codepen.io/szaranger/pen/QNQvYd'>Angular Translate</a> by Sean Amarasinghe
  (<a href='https://codepen.io/szaranger'>@szaranger</a>) on <a href='https://codepen.io'>CodePen</a>.
    </iframe>
    <h2>{`Conclusion`}</h2>
    <p>{`Angular Translate is a great library for solving localization as well as internationalization and it’s very easy to add to your own Angular web sites.`}</p>
    <p>{`The Github source for this article can be found in `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/angular-translate-intro"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      